import { useState } from 'react'
import QProductSidebar from './modules/Product_sidebar'
import QProductMeshViewer from './modules/QProductMeshViewer'
import './styles.css'

function fetch_productlist() {
  //const p_list = ['QWTA', 'QPreAmplifier', 'Optimizer4D']

  const new_plist = [['QWTA'], ['QPreAmplifier'], ['Optimizer4D']]

  return new_plist
}

export default function App() {
  
  const [selectedProduct, setSelectedProduct] = useState("QWTA")

  // Callback function to update selectedProduct state
  const handleSelectProduct = (product) => {
    setSelectedProduct(product)
    console.log('Callback')
  }

  return (
    <div className='App'>
        <div className="sidebar">
          {
            <QProductSidebar
              products={fetch_productlist()}
              onSelectProduct={handleSelectProduct}
            />
          }
        </div>
        <div className="viewport">
          <QProductMeshViewer selectedProduct={selectedProduct} />
          <div id="popupContainer">
            <div id="popupText">Popup Text</div>
          </div>
        </div>
    </div>
  )
}
