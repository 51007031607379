import { Canvas } from '@react-three/fiber'
import { Stats, OrbitControls } from '@react-three/drei'
import { Leva, useControls } from 'leva'
import Lights from '../modules/Lights'
import Product from '../modules/Product'
import Models from '../models.json'

export default function QProductMeshViewer(props) {
   const color = useControls({ value: '#202020' })

   const { model } = useControls({
      model: {
         value: 'QWTA',
         options: Object.keys(Models)
      }
   })

   const url = Models[props.selectedProduct]
   

   return (
      <>
         <Leva collapsed={true}/>

         <Canvas camera={{ position: [0, 0, 1] }}>
            <color attach="background" args={[color.value]} />
            <Lights intensity={3} />
            <group>
               <Product
                  selectedProduct={props.selectedProduct}
                  url={url}
               />
            </group>
            {/* <Stats /> */}
            <OrbitControls autoRotate/>
         </Canvas>
      </>
      
   )
}

