import React from 'react';
import { useState } from 'react';


function QProductSidebar({products, onSelectProduct}) {
  const [activeItem, setActiveItem] = useState("QWTA");
  var product_list = products;



  const handleProductSelection = (product) => {
    setActiveItem(product);
    onSelectProduct(product);
  };

  return (
    <div className="sidebar">
      <div style={{ marginTop: '20px' }}></div>
      <h2>Our QASS Products</h2>
      <ul class="list-group">
        <h5>Sensors</h5>
        {product_list[0].map((product, index) => (
        <li
          className={`list-group-item ${product === activeItem ? 'active' : ''}`}
          aria-current={product === activeItem ? 'true' : 'false'}
          key={index}
          onClick={() => handleProductSelection(product)}
          >
          {product}
        </li>
        ))}
        <hr></hr>
        <h5>Preamplifiers</h5>
        {product_list[1].map((product, index) => (
        <li
          className={`list-group-item ${product === activeItem ? 'active' : ''}`}
          aria-current={product === activeItem ? 'true' : 'false'}
          key={index}
          onClick={() => handleProductSelection(product)}
          >
          {product}
        </li>
        ))}
        <hr></hr>
        <h5>Measurement System</h5>
        {product_list[2].map((product, index) => (
        <li
          className={`list-group-item ${product === activeItem ? 'active' : ''}`}
          aria-current={product === activeItem ? 'true' : 'false'}
          key={index}
          onClick={() => handleProductSelection(product)}
          >
          {product}
        </li>
        ))}
      </ul>
      <div className='bottom-text'>Copyright 2023 - QASS GmbH</div>

    </div>
  );
}

export default QProductSidebar;







// import React from 'react';


// function QProductSidebar({products, onSelectProduct}) {
//   var product_list = products;

//   const handleProductSelection = (product) => {
//     onSelectProduct(product);
//   };

//   return (
//     <div className="sidebar">
//       <h2>Products</h2>
//       <h4>Choose a product to view it in 3D</h4>
//       <ul>
//       {product_list.map((product, index) => (
//         <li key={index} onClick={() => handleProductSelection(product)}>
//           {product}
//         </li>
//       ))}
//       </ul>
//     </div>
//   );
// }

// export default QProductSidebar;