import { useRef, useEffect, useState } from 'react'
import { useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import { Suspense } from 'react'
import { Html, useGLTF } from '@react-three/drei'

export default function Product(props) {
   const {selectedProduct, url} = props
   
   const { scene, animations } = useGLTF(url)
   const [cache, setCache] = useState({})

   const ref = useRef()
   //let gltf = useRef()

   let animActions = useRef([])
   let mixer = useRef(new THREE.AnimationMixer())
   let lastProduct = useRef(selectedProduct)
   let lastAction = useRef(null)
   let currentAction = useRef(null)

   const load_anims = () => {
      if (animations) {
         const anims = animations

         mixer.current = new THREE.AnimationMixer(scene)

         const actions = []

         for (let i = 0; i < 3; i++) {
            const action = mixer.current.clipAction(anims[i])

            if (i === 1) {
               action.setLoop(THREE.LoopOnce, 1)
               action.clampWhenFinished = true
            } else if (i === 2) {
               action.setLoop(THREE.LoopOnce, 1)
               action.clampWhenFinished = true
            }

            actions.push(action)
         }

         animActions.current = actions
         currentAction.current = animActions.current[0]
      }
   }

   const startAnimations = {
      default: () => playAnimations(animActions.current[0]),
      explode: () => playAnimations(animActions.current[1]),
      rebuild: () => playAnimations(animActions.current[2])
   }

   const playAnimations = (actionTodo) => {
      if (currentAction.current !== actionTodo) {
         currentAction.current.fadeOut()
         currentAction.current.stop()
         lastAction.current = currentAction.current
         currentAction.current = actionTodo
         currentAction.current.play()
      } else {
         currentAction.current.fadeOut()
         currentAction.current.stop()
         currentAction.current.play()
      }
   }


   load_anims()


   useEffect(() => {
      if (
         lastProduct.current !== selectedProduct &&
         selectedProduct !== null
      ) {
         lastProduct.current = selectedProduct
      }
   }, [url])

   useFrame((_, delta) => {
      if (mixer) {
         mixer.current.update(delta)
      }
   })

   if (!cache[url]) {
      const annotations = []

      scene.traverse((o) => {
         if (o.userData.prop) {
            annotations.push(
               <Html
                  key={o.uuid}
                  position={[o.position.x, o.position.y, o.position.z]}
                  distanceFactor={0.25}>
                  <div className="annotation">{o.userData.prop}</div>
               </Html>
            )
         }
      })

      console.log(
         'Caching JSX for url ' +
            url
      )
      setCache({
         ...cache,
         [url]: (
            <Suspense>
               {/* <Model url={models[models.findIndex((m) => m.title === title)].url}/> */}
               <primitive object={scene} ref={ref}>
                  {annotations} 
               </primitive>
               <Html
                  style={{
                     position: 'fixed',
                     bottom: '-50vh',
                     left: 0,
                     width: '100%',
                     display: 'flex'
                  }}
                  position={[0, 0, 0]}>
                  <div
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginBottom: '1rem',
                        flex: 1,
                        zIndex: 9999
                        
                     }}>
                     <button className="glow-on-hover" type="button" onClick={() => startAnimations['default']()}>
                        Default Animation
                     </button>
                     <button className="glow-on-hover" type="button" onClick={() => startAnimations['explode']()}>
                        Explode Animation
                     </button>
                     <button className="glow-on-hover" type="button" onClick={() => startAnimations['rebuild']()}>
                        Rebuild Animation
                     </button>
                  </div>
               </Html>
            </Suspense>
         )
      })
   }

   return (
      cache[url]
   )
}

