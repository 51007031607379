import { useRef } from 'react'
import { folder, Leva, useControls } from 'leva'
import * as THREE from 'three'
import { Sphere } from '@react-three/drei'

export default function Lights(props) {
   const pointLight_bottom_1 = useRef()
   const pointLight_bottom_2 = useRef()
   const pointLight_top_1 = useRef()
   const pointLight_top_2 = useRef()
   const pointLight_right = useRef()
   const pointLight_left = useRef()
   const ambientLight = useRef()

   const debug_sphere_1 = useRef()
   const debug_sphere_2 = useRef()
   const debug_sphere_3 = useRef()
   const debug_sphere_4 = useRef()
   const debug_sphere_5 = useRef()
   const debug_sphere_6 = useRef()


   useControls('Lights', {
      floorLights: folder({
         color: {
            value: 'white',
            onChange: (v) => {
               pointLight_bottom_1.current.color = new THREE.Color(v)
               pointLight_bottom_2.current.color = new THREE.Color(v)
            }
         },
         intensity: {
            value: props.intensity,
            onChange: (v) => {
               pointLight_bottom_1.current.intensity = v
               pointLight_bottom_2.current.intensity = v
            }
         },
         visible: {
            value: true,
            onChange: (v) => {
               pointLight_bottom_1.current.visible = v
               pointLight_bottom_2.current.visible = v
            }
         }
      })
   })

   useControls('Top Lights', {
      color: {
         value: 'white',
         onChange: (v) => {
            pointLight_top_1.current.color = new THREE.Color(v)
            pointLight_top_2.current.color = new THREE.Color(v)
            pointLight_left.current.color = new THREE.Color(v)
            pointLight_right.current.color = new THREE.Color(v)
         }
      },
      intensity: {
         value: props.intensity,
         onChange: (v) => {
            pointLight_top_1.current.intensity = v
            pointLight_top_2.current.intensity = v
            pointLight_left.current.intensity = v
            pointLight_right.current.intensity = v

         }
      },
      visible: {
         value: true,
         onChange: (v) => {
            pointLight_top_1.current.visible = v
            pointLight_top_2.current.visible = v
            pointLight_left.current.visible = v
            pointLight_right.current.visible = v
         }
      }
   })

   useControls('Ambient Light', {
      color: {
         value: 'white',
         onChange: (v) => {
            ambientLight.current.color = new THREE.Color(v)
         }
      },
      intensity: {
         value: props.intensity,
         onChange: (v) => {
            ambientLight.current.intensity = v
         }
      },
      visible: {
         value: true,
         onChange: (v) => {
            ambientLight.current.visible = v
         }
      }
   })

   useControls('Debug Spheres', {
      visible: {
         value: false,
         onChange: (v) => {
            debug_sphere_1.current.visible = v
            debug_sphere_2.current.visible = v 
            debug_sphere_3.current.visible = v 
            debug_sphere_4.current.visible = v 
            debug_sphere_5.current.visible = v 
            debug_sphere_6.current.visible = v 
         }
      }
   })

   return (
      <group name="Lights">
         <pointLight
            position={[-5, -5, 0]}
            name="light1"
            intensity={props.intensity}
            ref={pointLight_bottom_1}
         />
         <Sphere position={[-5, -5, 0]} ref={debug_sphere_1}/>
         <pointLight
            position={[5, -5, 0]}
            name="light2"
            intensity={props.intensity}
            ref={pointLight_bottom_2}
         />
         <Sphere position={[5, -5, 0]} ref={debug_sphere_2}/>
         <pointLight
            position={[-5, 5, 0]}
            name="light3"
            intensity={props.intensity}
            ref={pointLight_top_1}
         />
         <Sphere position={[-5, 5, 0]} ref={debug_sphere_3}/>
         <pointLight
            position={[5, 5, 0]}
            name="light4"
            intensity={props.intensity}
            ref={pointLight_top_2}
         />
         <Sphere position={[5, 5, 0]} ref={debug_sphere_4}/>
         <pointLight
            position={[0, 0, 5]}
            name="light_left"
            intensity={props.intensity}
            ref={pointLight_left}
         />
         <Sphere position={[0, 0, 5]} ref={debug_sphere_5}/>
         <pointLight
            position={[0, 0, -5]}
            name="light_right"
            intensity={props.intensity}
            ref={pointLight_right}
         />
         <Sphere position={[0, 0, -5]} ref={debug_sphere_6}/>

         <ambientLight
            position={[0, 0, 0]}
            name="Ambient Light"
            intensity={1}
            ref={ambientLight}
         />
      </group>
   )
}
